import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import Layout from '../../components/layout'
import SiteWidthWrapper from '../../components/wrappers/siteWidthWrapper'
import Seo from '../../components/seo'
import HeroImageA from '../../components/MainHero'

const GalleryMisc = () => {
  const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px, max-content));
    justify-content: space-evenly;

    column-gap: 1em;
    row-gap: 2em;
  `
  // for staticImage start
  const width = 360
  const height = 360
  // for staticImage end
  return (
    <Layout>
      <Seo
        title="Misc Welding Projects Gallery"
        description="Some of our work"
      />

      {/* Hero Image is found in /components/MainHero.js */}
      <HeroImageA />

      <SiteWidthWrapper>
        <h1>Misc Projects Gallery</h1>
        <p style={{ textAlign: `center`, marginBottom: `64px` }}>
          Below is a brief collection of our work. This gallery includes a few
          in-progress pieces, befores, and afters in no particular order.
        </p>

        <div style={{ width: `100%`, margin: `0 auto` }}>
          <GridWrapper>
            <StaticImage
              src="../../images/Misc Projects/Arch.jpg"
              alt="gallery image 1"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="eager"
            />
            <StaticImage
              src="../../images/Misc Projects/Arch-2.jpg"
              alt="gallery image 1"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="eager"
            />
            <StaticImage
              src="../../images/Misc Projects/Boat.jpg"
              alt="gallery image 2"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="lazy"
            />
            <StaticImage
              src="../../images/Misc Projects/Boat-2.jpg"
              alt="gallery image 4"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="lazy"
            />
            <StaticImage
              src="../../images/Misc Projects/Drum-Barrel.jpg"
              alt="gallery image 4"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="lazy"
            />
            <StaticImage
              src="../../images/Misc Projects/Elevator.jpg"
              alt="gallery image 5"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="lazy"
            />
            <StaticImage
              src="../../images/Misc Projects/Elevator-2.jpg"
              alt="gallery image 6"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="lazy"
            />
            <StaticImage
              src="../../images/Misc Projects/Gear.jpg"
              alt="gallery image 7"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="lazy"
            />
            <StaticImage
              src="../../images/Misc Projects/Out-Door-BBQ.jpg"
              alt="gallery image 8"
              objectFit="contain"
              width={width}
              height={height}
              layout="fixed"
              quality={95}
              placeholder="dominantColor"
              formats={['AUTO', 'WEBP', 'AVIF']}
              loading="lazy"
            />
          </GridWrapper>
        </div>
      </SiteWidthWrapper>
    </Layout>
  )
}

export default GalleryMisc
